import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/inicio.vue'),
    meta:{
      title:"Admon - Inicio"
    }
  },
  {
    path:'/login',
    name:'login',
    component:() => import('../views/login.vue'),
    meta: {
      hideNavbar: true,
      title:"Admon - Login"
     }
  },
  {
    path:'/consultarEstudiantes',
    name:'consultarEstudiantes',
    component:() => import('../views/consultarEstudiantes.vue'),
    meta:{
      title:"Admon - Consultar estudiantes"
    }
  },
  {
    path:'/crearEstudiante',
    name:'crearEstudiante',
    component:() => import('../views/crearEstudiante.vue'),
    meta:{
      title:"Admon - Crear estudiante"
    }
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) =>{
  document.title = `${to.meta.title}`;
  next();
})

export default router

