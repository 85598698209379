import { createStore } from 'vuex'
import { auth } from '../main';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
//import VuexPersistence from 'vuex-persist';
import { db } from '../main';
import { collection, getDocs,  query } from "firebase/firestore";
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

const ls = new SecureLS({
    isCompression: true
  })

const storeaAutenticacion = createStore({
    plugins: [createPersistedState({
        //variables que quiero que permanezcan incluso si se recarga la pagina
        paths: ["user",
            "tiposDocumentos",
            "clientes",
            "materiasPrimas",
            "vehiculos",
            "conductores",
            "etiquetas",
            "productos",
            "gastos"
        ],
        overwrite: true,
        storage: {
          getItem: (key) => {return ls.get(key)},
          setItem: (key, value) => {ls.set(key, value)},
          removeItem: (key) => {ls.remove(key)}
        }
      })],
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: "ninguno",
        tiposDocumentos: "ninguno",
        clientes: "ninguno",
        materiasPrimas: "ninguna",
        vehiculos: "ninguno",
        conductores: "ninguno",
        gastos: "ninguno",
        etiquetas: "ninguna",
        productos: "ninguno",
        ultimoDocumento: "ninguno",
    },
    mutations: {
        setUser(state, payload){state.user = payload},
        setTiposDocumentos(state, payload){state.tiposDocumentos = payload},
        setClientes(state, payload){state.clientes = payload},
        setMateriasPrimas(state, payload){state.materiasPrimas = payload},
        setVehiculos(state, payload){state.vehiculos= payload},
        setConductores(state, payload){state.conductores= payload},
        setGastos(state, payload){state.gastos= payload},
        setEtiquetas(state, payload){state.etiquetas= payload},
        setProductos(state, payload){state.productos= payload},
        setUltimoDocumento(state, payload){state.ultimoDocumento= payload}
    },
    actions: {

        async login(context, { email, password }){
            const response = await signInWithEmailAndPassword(auth, email, password)
            if (response) {
                context.commit('setUser', response.user)
            } else {
                throw new Error('login failed')
            }
        },
        async logout(context){
            await signOut(auth);
            context.commit('setUser', "ninguno");
        },
        async consultarTiposDocumento(context){
            let tiposDocumentos = [];
            const q = query(collection(db, "tiposDocumentos"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                tiposDocumentos.push({...doc.data(), id:doc.id})
            });
            //console.log("consultarTiposDocumento en actions:")
            //console.log(tiposDocumentos)
            context.commit('setTiposDocumentos', tiposDocumentos);
        },
        async consultarClientes(context){
            let clientes = [];
            const q = query(collection(db, "clientes"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                clientes.push({...doc.data(), id:doc.id})
            });
            context.commit('setClientes', clientes);
        },
        async consultarMateriasPrimas(context){
            let materiasPrimas = [];
            const q = query(collection(db, "materiasPrimas"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                materiasPrimas.push({...doc.data(), id:doc.id})
            });
            //console.log("consultarMateriasPrimas en actions:")
            //console.log(materiasPrimas)
            context.commit('setMateriasPrimas', materiasPrimas);
        },
        async consultarVehiculos(context){
            let vehiculos = [];
            const q = query(collection(db, "vehiculos"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                vehiculos.push({...doc.data(), id:doc.id})
            });
            context.commit('setVehiculos', vehiculos);
        },
        async consultarConductores(context){
            let conductores = [];
            const q = query(collection(db, "conductores"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                conductores.push({...doc.data(), id:doc.id})
            });
            context.commit('setConductores', conductores);
        },
        async consultarGastos(context){
            let gastos = [];
            const q = query(collection(db, "gastos"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                gastos.push({...doc.data(), id:doc.id})
            });
            context.commit('setGastos', gastos);
        },
        async consultarEtiquetas(context){
            let etiquetas = [];
            const q = query(collection(db, "etiquetas"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                etiquetas.push({...doc.data(), id:doc.id})
            });
            //console.log("etiquetas en actions:")
            //console.log(etiquetas);
            context.commit('setEtiquetas', etiquetas);
        },
        //async consultarProductos(context){
        async consultarProductos (context) {

            /*cuento cuantos productos hay creados en firestore
            db.collection('productos').get().then(snap => {
                let size = snap.size
                console.log("hay "+size+" productos en firestore");
            });
            */

            let productos = [];
            const q = query(collection(db, "productos"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                productos.push({...doc.data(), id:doc.id})
            });
            //console.log("productos en actions:")
            //console.log(productos);
            context.commit('setProductos', productos);
        }
        /*
        async consultarUltimoDocumento(context, coleccion){
            //console.log("coleccion:"+coleccion);
            const q = query(collection(db, coleccion), limit(1), orderBy("id", "desc"));
            const el = await getDocs(q);
            const ultimoDocumento = el.docs.map((doc) => ({
                ...doc.data()
            }));            
            context.commit('setUltimoDocumento', ultimoDocumento);
        }*/
    },
    getters:{        
        obtenerProductosEnEstado (state) {
            return state.productos;
        }
    }/*, plugins:[new VuexPersistence({storage: window.localStorage}).plugin]*/
})
// export the store
export default storeaAutenticacion