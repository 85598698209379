<script>
  import { onBeforeMount, computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import firebase from 'firebase/compat/app';
  import { useStore } from 'vuex';
 

  export default{
    setup(){
            
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      onBeforeMount(() =>{
        //const user = firebase.auth().currentUser;
        //console.log(user.email);
        //alert("se ejecuto onBeforeMount en App.vue");
        firebase.auth().onAuthStateChanged((user)=>{          
          if(!user){
            console.log("APP.vue = no hay usuario logueado");
            localStorage.setItem("usuarioLogueado","ninguno");
            router.replace('/login');
          }else if(route.path == "/login"){
            localStorage.setItem("usuarioLogueado", user.email);
            router.replace('/');
          }
        });
      });

      const cerrarSesion = () =>{
        store.dispatch('logout');
      }

      return{
        cerrarSesion,
        usuarioLogueado: computed(() => store.state.user.email)
      }
    }    
  }

</script>

<template>
  <link rel="icon" type="image/png" href="./assets/favicon.ico"/>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
  <div class="header"><img src="./assets/logo.png" class="logo"/>
  <br>
  <h3>Usuario: {{usuarioLogueado}}</h3>
  </div>
  <nav v-if="!$route.meta.hideNavbar" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">                       
            <router-link class="nav-link" aria-current="page" to="/">
              <span class="material-icons md-24">
                home
              </span>Inicio</router-link>
          </li>
          <li class="nav-item dropdown">            
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="material-icons md-24">account_circle</span>Estudiantes
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/crearEstudiante">Crear</router-link></li>
              <li><router-link class="dropdown-item" to="/consultarEstudiantes">Consultar</router-link></li>
            </ul>
          </li>
          <li class="nav-item">             
            <button class="nav-link btn btn-outline-danger btn-sm" @click="cerrarSesion" aria-current="page">
              <span class="material-icons md-24">logout</span>
              Cerrar Sesion
            </button>
          </li>
          
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
</template>


<style lang="scss">
  .header {
    overflow: hidden;
    padding: 20px 20px;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0 8px 10px rgba(0,0,0,0.5);
    padding-bottom: 10px;
    /*text-align: center;*/
  }
  body{
    background: #ffffff;
    color: #2c3e50;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  a {
    color:inherit;
  }
  
</style>