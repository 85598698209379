import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import storeaAutenticacion from './store/index'

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'vue-select/dist/vue-select.css';

const firebaseConfig = {
    apiKey: "AIzaSyAifb0RnJ5tOAgs5HAOboc0omCn47p-_bs",
    authDomain: "cocobu---academico.firebaseapp.com",  
    projectId: "cocobu---academico",  
    storageBucket: "cocobu---academico.appspot.com",  
    messagingSenderId: "353706996130",  
    appId: "1:353706996130:web:c5facdc9948e45ee2402fb",  
    measurementId: "G-FPTMLMNM7W"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db, Swal };

import vSelect from 'vue-select';

const app = createApp(App);
app.component('v-select',vSelect);
app.use(router);
app.use(storeaAutenticacion);
app.mount('#app');
import "bootstrap/dist/js/bootstrap.js"
